.home__greeting{
  font-family: $font-display;
  color: $color-font-light;
  width: 60%;
  min-width: 1000px;
  height: 80vh;
  margin: 0 auto;
  display: grid;
  align-content: center;
  justify-items: start;

  @media only screen and (max-width:$bp-hg) {
    min-width: max-content;
  }

  @media only screen and ( max-width:$bp-sm) {
    min-width: auto;
    width: 70%;
    height: 80vh;

  }

  &__hello{
    font-family: Arial;
    font-size: 6rem;
    text-transform: capitalize;
    position: relative;
    -webkit-text-stroke: 1px;
    &::before {
      content: 'Hello,';
      position: absolute;
      top: 20%;
      left: 0;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke: 1px;
      -webkit-text-stroke-width: .5px;
    }
    @media only screen and (max-width:$bp-md) {
      font-size: 5rem;
    }
    @media only screen and ( max-width:$bp-sm) {
      font-size: 4rem;
    }
  }

  &__main-heading{
    color: $color-secondary-light;
    font-size: 10rem;

    @media only screen and (max-width:$bp-md) {
      font-size: 7rem;
    }
    @media only screen and ( max-width:$bp-sm) {
      font-size: 5rem;
    }
  }

  &__sub-heading{
    font-size: 5rem;
    @media only screen and (max-width:$bp-md) {
      font-size: 4rem;
    }
    @media only screen and ( max-width:$bp-sm) {
      font-size: 3rem;
    }
  }


}