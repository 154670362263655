.home__skills {
  width: 70%;
  margin-left: 20%;
  padding-top: 10rem;
  @media only screen and (max-width: $bp-hg) {
    width: 70%;
    margin-left: 13%;
  }
  @media only screen and (max-width: $bp-sm) {
    width: 65%;
    margin-left: 17.5%;
  }

  &__heading {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    column-gap: 2rem;
    height: 6rem;

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background-color: $color-font-bright;
    }
  }

  &__list {
    width: 100%;
    border-left: 1px solid $color-font-bright;
    padding: 0 5rem;
    margin-top: 5rem;
    margin-left: 2rem;
    font-size: 6rem;

    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    justify-items: center;

  @media only screen and (max-width: $bp-sm) {
    grid-template-columns: repeat(3, 1fr);
    border-left:none;
    margin-left: 0;
    padding: 0;
    justify-items: center;
    justify-content: space-evenly;

  }
  }

  &__icon {
    color: $color-font-bright;
    transform-origin: start;
    transition: all 0.3s linear;
    &:hover {
      transform: rotate(5deg) scale(1.05);
    }
  }
}

.enterFromRight {
  transform: translateX(0);
  opacity: 1;
}
