.home__projects {
  width: 100%;


  &__heading {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    align-items: center;
    column-gap: 2rem;
    height: 6rem;
    width: 60%;
    margin-left: 20%;

    &::after,
    &::before {
      content: "";
      width: 100%;
      height: 1px;
      background-color: $color-font-bright;
    }
  }

  &__container {
    max-width: calc(100% - 5rem);
    margin: 0 auto;
    margin-top: 6rem;
    display: flex;
    flex-wrap: wrap;
    // grid-template-columns: repeat(2, 1fr);
    // row-gap: 6rem;
    // column-gap: 4rem;
    justify-items: center;
    justify-content: center;

    @media only screen and (max-width:$bp-hg) {
      max-width: calc(100% - 15rem);
    }
  }

  &__item {
    width: 30rem;
    height: 30rem;
    margin: 0 4rem;
    
    @media only screen and (max-width:$bp-hg) {
     margin: 0 2rem;
     margin-bottom: 3rem;
    }
    
    &-side {
      background-color: $color-tertiary;
      border-radius: 2px;
      box-shadow: $shadow-light;
      @media only screen and (hover: none) {
       border-radius: 0;
      }
    }

    &-side-front {
      padding: 3rem;
      display: grid;
      grid-template-rows: min-content 1fr 3rem;
      grid-template-columns: min-content 1fr;
      justify-content: space-between;
      justify-items: end;
      align-items: center;
      @media only screen and (max-width:$bp-sm), screen and (hover: none) {
        padding-bottom: 0;
        grid-template-rows: min-content 1fr;
      }
    }

    &-side-back {
      display: grid;
      grid-template-rows: 4fr 1fr 1fr;
      // background-color: #fff;
      padding: 2rem;
      justify-content:  center;
    }
  }

  &__img {
    width: 100%;
    
    
    & > img {
      width: 100%;
      aspect-ratio: 5 / 3;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    grid-column: 2 / 3;
    margin-right: 2rem;
    text-align: center;
    font-family: $font-display;
    font-size: 1.6rem;
    color: $color-font-light;
  }

  &__icon {
    fill: $color-font-bright;
    width: 3rem;
    height: 3rem;
  }

  &__description{
    grid-column: 1 / 3;
    color: $color-font-light;
    font-size: 1.4rem;
    font-family: $font-text;
    align-self: start;
    padding-top: 2rem;
    @media only screen and (hover: none) {
      height: 20rem;
      overflow: scroll;
    }
  }
  &__sub-description{
    margin-top: 2rem;
    color: $color-font-bright;
    font-size: 1.3rem;
    font-family: $font-text;
    align-self: stretch;
    text-align: center;
  }

  &__btns{
    align-self: end;
    display: flex;
    justify-content: space-evenly;
  }

  &__btn{
    width: 2rem;
    height: 2rem;
  }


}
