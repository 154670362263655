.link {
  &__media {
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 5rem;
    height: 30rem;
    display: grid;
    grid-template-rows: max-content max-content 1fr;
    justify-items: center;
    row-gap: 3rem;
    &__btn {
      width: 3rem;
      height: 3rem;
    }
    &__line {
      width: 1px;
      height: 100%;
      background-color: $color-font-light;
    }
    @media only screen and ( max-width:$bp-sm) {
      left: 2rem;
    }
  }

  &__email {
    position: fixed;
    z-index: 100;
    bottom: 30vh;
    right: 5rem;
    height: 40rem;

    a {
      writing-mode: vertical-rl;
      font-weight: 400;
      font-size: 1.5rem;
      font-family: $font-display;
      color: $color-font-light;
      display: grid;
      grid-template-columns: 1fr min-content 1fr;
      align-items: center;
      height: 100%;
      column-gap: 1rem;

      &::before,
      &::after {
        content: "";
        height: 100%;
        width: 1px;
        background-color: $color-font-light;
      }
      &:hover {
        color: $color-font-bright;
      }

      &:hover::before,
      &:hover::after {
        background-color: $color-font-bright;
      }
    }
    @media only screen and ( max-width:$bp-sm) {
      right: 2rem;
    }
  }

  &__toTop{
    position: fixed;
    right: 5rem;
    bottom: 3rem;
    z-index: 100;
    transition: all 1s linear;
    animation: bounceInDown 1.5s infinite;
    &__btn{
      // filter: drop-shadow($shadow-dark);
      filter: drop-shadow(3px 6px 6px rgba($color-black, .333));
      // border-radius: 50%;
      // box-shadow: $shadow-dark;
      width: 5rem;
      height: 5rem;

    }
  }
}
