.home__contact {
 display: grid;
 justify-items: center;

  &__heading {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    align-items: center;
    column-gap: 2rem;
    height: 6rem;
    width: 20%;

    &::after,
    &::before {
      content: "";
      width: 100%;
      height: 1px;
      background-color: $color-font-bright;
    }

    @media only screen and ( max-width:$bp-sm) {
      width: 50%;
    }
  }

  &__text{
    width: 60%;
    color: $color-font-light;
    font-family: $font-display;
    font-size: 2rem;
    letter-spacing: 1px;
    text-align: center;
  }

}

.copy {
  color:$color-font-light;
  font-family: $font-text;
  font-size: 1.2rem;
}