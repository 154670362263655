@import "../../../sass/variables";

.share__flip-card {
  color:#fff;

  perspective: 150rem;
  -moz-perspective: 150rem;

  &__front,
  &__back {
    height: 100%;
    width: 100%;
    backface-visibility: hidden;    
    overflow: hidden;
    position: relative;
    transition: all .5s linear;
  }


  &__back {
    position: absolute;
    top: 0;
    left: 0;
    transform: rotateY(180deg);
  }

  &:hover &__front{
    transform: rotateY(-180deg);
  }

  &:hover &__back{
    transform: rotateY(0);
  }

  @media only screen and (hover: none) {
    overflow: hidden;
    height: auto;
    box-shadow: $shadow-dark;


    &__front,
    &__back {
      height: auto;
      backface-visibility:visible;   
      overflow: auto; 
      position: relative;
      transform: rotateY(0deg);
    }
    
  
  }
}