*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%; //10px
  scroll-behavior: smooth;

  @media only screen and (max-width:$bp-lg) {
    font-size: 56.25%;
  }
  @media only screen and (max-width:$bp-md) {
    font-size: 50%;
  }
  @media only screen and (max-width:$bp-md) {
    font-size: 50%;
  }
}

body {
  font-size: $font-text;
  font-weight: 400;
  line-height: 1.7;
}

ul {
  list-style: none;
}

a,
a:link,
a:visited{
  text-decoration: none;
}